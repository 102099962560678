import { mapState } from 'vuex';
export default {
  name: "management",
  data() {
    return {
      title: this.$api.api_title + 'uploadImage/',
      managementList: []
    };
  },
  mounted() {},
  watch: {},
  created() {
    this.getData();
  },
  computed: {
    ...mapState({
      language: state => state.language
    })
  },
  methods: {
    getData() {
      this.$http.get(this.$api.managementList).then(res => {
        if (res.code == 0) {
          this.managementList = res.data;
          // if(this.$store.state.language==1){
          // 	this.about_company = res.data[0];  
          // }if(this.$store.state.language==2){
          // 	this.about_company = res.data[1];  
          // }if(this.$store.state.language==3){
          // 	this.about_company = res.data[2];  
          // }			         			               				   
        }
      });
    }
  }
};